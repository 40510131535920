import Seo from 'modules/app/components/Seo';
import { CookiePolicyPage } from 'modules/legal';
import React from 'react';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const CookiePolicy: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Cookie Policy - Roango"
        description="Roango uses cookies and similar tracking technologies on roango.net, Roango-branded applications, and Roango-branded websites."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <CookiePolicyPage />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default CookiePolicy;
